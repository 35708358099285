import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./UserEditPrincipalPage.module.css";
//import "react-modern-calendar-datepicker/lib/DatePicker.css";
//import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
import { UserContext } from "../../../../../context/UserContext";
import { BackendUser } from "../../../../../types/users.types";
import { Button } from "../../../button/Button";
import { CheckBox } from "../../../checkbox/CheckBox";
import principalUsersEditService from "../../../../../services/principal/principal.users.edit.service";
import principalUsersDeleteService from "../../../../../services/principal/principal.users.delete.service";
import usersResetService from "../../../../../services/users/users.reset.service";
import principalUsersTimeCorrectionService from "../../../../../services/principal/principal.users.timecorrection.service";

export const UserEditPrincipalPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);

  const [user, setUser] = useState<BackendUser>();
  const [username, setUsername] = useState("");
  const [active, setActive] = useState(false);
  const [canExpire, setCanExpire] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [adjustMessage, setAdjustMessage] = useState("");
  const [adjustment, setAdjustment] = useState("");

  //const [selectedDay, setSelectedDay] = useState<DayValue>();
  const [selectedDay, setSelectedDay] = useState<string>(location.state.expires_on);

  useEffect(() => {
    setUser(location.state);
    setUsername(location.state.username);
    setActive(location.state.active === 1 ? true : false);
    // setSelectedDay({
    //   day: new Date(location.state.expires_on).getDate(),
    //   month: new Date(location.state.expires_on).getMonth() + 1,
    //   year: new Date(location.state.expires_on).getFullYear(),
    // });
    setCanExpire(location.state.can_expire === 1 ? true : false);
  }, [location.state]);

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const selectedDayInputRef = useRef<HTMLInputElement>(null);
  const adjustmentInputRef = useRef<HTMLInputElement>(null);

  if (userContext) {
    if (!userContext.user) {
      return <div>403</div>;
    }
    if (userContext.user.type !== "admin") {
      return <div>403</div>;
    }
  }

  async function save(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (user && selectedDay) {
      //console.log(selectedDay);
      //const expiresOn = new Date(selectedDay.year ?? 0, selectedDay.month - 1 ?? 0, selectedDay.day + 1 ?? 0).toISOString().split("T")[0];
      //console.log(expiresOn);

      if (selectedDay.split("-").length !== 3) {
        return;
      }

      const yyyy = parseInt(selectedDay.split("-")[0]);
      const mm = parseInt(selectedDay.split("-")[1]);
      const dd = parseInt(selectedDay.split("-")[2]);
      const expiresOn = new Date(yyyy ?? 0, mm - 1, 0, dd + 1).toISOString().split("T")[0];

      const statusCode = await principalUsersEditService.edit(user.id, username, active ? "1" : "0", canExpire ? "1" : "0", expiresOn, user.organization_id);

      if (statusCode === 200) {
        navigate(-1);
      } else {
        setError("user already exsist");
      }
    }
  }
  function reset(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (user) {
      usersResetService.reset(user.id, user.organization_id, user.username);
      setMessage("The google user was reset! User must re-login to set a new password.");
    }
  }
  function remove(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (user) {
      principalUsersDeleteService.delete(user.id, user.organization_id);
      navigate(-1);
    }
  }
  function activeChanged(active: boolean) {
    setActive(active);
  }
  function canExpireChanged(active: boolean) {
    setCanExpire(active);
  }

  function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async function applyTimeCorrection() {
    const isValidNumber = /^-?\d+(\.\d+)?$/.test(adjustment);
    if (!isValidNumber) {
      setAdjustMessage("Adjustment value is not a number");
      return;
    }
    setAdjustMessage("");
    const timeCorrectionValue = parseInt(adjustment, 10);
    //console.log(timeCorrectionValue);
    let correctionValue = 0;
    if (Number(timeCorrectionValue) < 0) { // negative
      let positiveNumber = Math.abs(timeCorrectionValue);
      let inMinutes = positiveNumber * 60;
      correctionValue = inMinutes * -1;
    } else { // positive
      correctionValue = timeCorrectionValue * 60;
    }

    //console.log(correctionValue);

    if (user) {
      const result = await principalUsersTimeCorrectionService.apply(user.id, user.organization_id, correctionValue);
      if (result === 200) {
        setAdjustMessage("Adjustment applied.");
        delay(1000).then(() => {
          setAdjustMessage("");
        });
      }
    }
  }

  return (
    <div className={classes.container}>
      <h1>Edit user [{user?.username}]</h1>
      <br />
      <Button
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <br />
      <div className={classes.control}>
        <label htmlFor="username">Username</label>
        <input
          data-lpignore="true"
          type="text"
          required
          id="username"
          autoComplete="off"
          ref={usernameInputRef}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
      </div>
      <span className={classes.error}>{error}</span>
      <span className={classes.message}>{message}</span>
      <br />
      <CheckBox initialState={active} label="Active" onStateChange={activeChanged} />
      <CheckBox initialState={canExpire} label="Can expire" onStateChange={canExpireChanged} />
      <br />
      <div>
        {/* <p>Expires on</p>
        <DatePicker value={selectedDay} onChange={setSelectedDay} inputPlaceholder="Select a day" shouldHighlightWeekends /> */}
        <div className={classes.control}>
          <label htmlFor="selectedDay">Expire On (YYYY-MM-DD eg 2025-12-31)</label>
          <input
            data-lpignore="true"
            type="text"
            required
            id="selectedDay"
            autoComplete="off"
            ref={selectedDayInputRef}
            value={selectedDay}
            onChange={(e) => {
              setSelectedDay(e.target.value);
            }}
          />
        </div>
      </div>
      <br />
      <Button text="Save" onClick={save} />
      <Button text="Reset password" onClick={reset} />
      <Button text="Delete" onClick={remove} />
      <br />
      <br />
      <div>
        <div className={classes.control}>
          <label htmlFor="adjustment">Manual training time adjustment (in minutes)</label>
          <input
            data-lpignore="true"
            type="text"
            required
            id="adjustment"
            autoComplete="off"
            ref={adjustmentInputRef}
            value={adjustment}
            onChange={(e) => {
              setAdjustment(e.target.value);
            }}
          />
        </div>
        <Button text="Apply time correction" onClick={applyTimeCorrection} />
        <label>{adjustMessage}</label>
      </div>
    </div>
  );
};
