import axios from "../../api/axios.private";

class PrincipalUsersTimeCorrectionService {
  async apply(id: number, organizationid: string, timecorrection: number): Promise<number> {
    let result: number = 0;

    const config = {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    };

    const url = "/ui/principal/users/timecorrection";

    const data = {
      appid: "d75c9542-bafb-4b7a-a7ff-599885be56f4",
      id: id,
      organizationid: organizationid,
      timecorrection: timecorrection
    };

    await axios
      .post(url, data, config)
      .then((res) => {
        result = res.status;
      })
      .catch((err) => {
        console.log(err);
      });
    //.catch((err) => {});

    return result;
  }
}

const instance = new PrincipalUsersTimeCorrectionService();
export default instance;
