import classes from "./OrganizationPrincipalHeaderItem.module.css";

export const OrganizationPrincipalHeaderItem = () => {
  return (
    <div className={classes.gridcontainer}>
      <div className={classes.username}>Username</div>
      <div className={classes.trainingtime}>Trainingtime</div>
      <div className={classes.haspassword}>Has password</div>
      <div className={classes.active}>Active</div>
      <div className={classes.download}>Traininglog</div>
      <div className={classes.canexpire}>Can expire</div>
      <div className={classes.expireson}>Expires on</div>
      <div className={classes.edit}>Edit</div>
    </div>
  );
};
