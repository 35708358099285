import { BackendPrincipalItemProps } from "../../../../types/principal.types";
import classes from "./OrganizationPrincipalItem.module.css";

export const OrganizationPrincipalItem = ({ onClickEdit, onClickDownloadTraininglog, item }: BackendPrincipalItemProps) => {
  function onEdit() {
    onClickEdit(item);
  }
  function onDownloadlog() {
    onClickDownloadTraininglog(item);
  }

  function resolveHasTempPassword(has_temp_password: number) {
    return has_temp_password === 0 ? "Yes" : "No";
  }

  function resolveActive(active: number) {
    return active === 1 ? "Yes" : "No";
  }

  function resolveCanExpire(can_expire: number) {
    return can_expire === 1 ? "Yes" : "No";
  }

  function convertSecondsToHourMinutes(seconds: number): string {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0')
    return `${hours}h : ${minutes}m`
  }

  return (
    <div className={classes.gridcontainer}>
      <div className={classes.username}>{item.username}</div>
      <div className={classes.trainingtime}>{convertSecondsToHourMinutes(item.trainingtime)}</div>
      <div className={classes.haspassword}>{resolveHasTempPassword(item.has_temp_password)}</div>
      <div className={classes.active}>{resolveActive(item.active)}</div>
      <div className={classes.download} onClick={onDownloadlog}>
        Download
      </div>
      <div className={classes.canexpire}>{resolveCanExpire(item.can_expire)}</div>
      <div className={classes.expireson}>{item.expires_on}</div>
      <div className={classes.edit} onClick={onEdit}>
        Edit
      </div>
    </div>
  );
};
