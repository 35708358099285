import { BackendTrainingItem, BackendTraningEntry, ConverTo } from "../../types/training.types";

class TrainingFileService {
  _trainings: BackendTraningEntry[] = [];
  _filename: string = "";

  load(trainings: BackendTraningEntry[], filename: string) {
    this._trainings = trainings;
    this._filename = filename;
  }

  sendAs(type: ConverTo) {
    let ext = "";
    let fileData = "";
    if (type === ConverTo.CSV) {
      fileData = "sessionid;username;cockpitname;procedurename;errorcount;errortracking;evaltime;logintime;trainingmode";
      this._trainings.map((training) => {
        training.items.map((element) => {
          fileData += this.createCSVLine(element);
          return element;
        });
        return training;
      });
      ext = "csv";
    }
    if (type === ConverTo.JSON) {
      fileData = JSON.stringify(this._trainings);
      ext = "json";
    }

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = this._filename + "." + ext;
    link.href = url;
    link.click();
  }

  createCSVLine(trainingdata: BackendTrainingItem) {
    const seperator = ";";
    return (
      "\n" +
      trainingdata.session_id +
      seperator +
      trainingdata.username +
      seperator +
      trainingdata.cockpitname +
      seperator +
      trainingdata.procedurename +
      seperator +
      trainingdata.errorcount +
      seperator +
      trainingdata.errortracking +
      seperator +
      trainingdata.evaltime +
      seperator +
      trainingdata.logintime +
      seperator +
      trainingdata.trainingmode
    );
  }
}

const instance = new TrainingFileService();
export default instance;
